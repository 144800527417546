import styled from "styled-components";

export const CategorySidebar = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 40px;
  border: 1px solid #DEDFE4;
  border-radius: 16px;

  h2 {
    height: 60px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #161616;
  }
`

export const CategoriesList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`