import React, { useState } from 'react'
import Layout from 'src/components/Layout'
import ArticlesByCategories from './articlesByCategories/_index'
import { Hero } from 'src/pages/sections/hero/_index'
import { navigate } from 'gatsby'
import { View } from 'src/types/View'
import articles from 'src/assets/data/faq-babi/articles.json'
import { ArticlesModel } from 'src/pages/types';
import { normalizeString } from 'src/utils/normalizeString'

interface CategoriesPageProps {
  params: {
    categorie: string
  }
}

const CategoriesPage = (req: CategoriesPageProps) => {
  const [submitedSearch, setSubmitedSearch] = useState('')

  const contentMetas = {
    slug: '',
    metaTitle: 'Inter. Simplifica a vida.',
    metaDescription: '',
    noIndexOption: true,
    hideOpenAccountBottomForm: true,
    hideBabiChat: false,
  }

  const articlesList: ArticlesModel = articles

  const categoryMatchingUrl = (item) => {
    return normalizeString(item) === req.params.categorie
  }
  const selectedCategoryKey: string = Object.keys(articlesList).find(categoryMatchingUrl) as string

  const articlesByCategory = articlesList[selectedCategoryKey]

  return (
    <Layout pageContext={contentMetas}>
      <Hero
        isHomePage
        softView={true}
        submitedSearch={submitedSearch}
        setSubmitedSearch={setSubmitedSearch}
        view={View.ArticleByCategory}
        setView={(view) => {
          if (View.Search) {
            navigate(`/search?term=${submitedSearch}`)
          } else {
            navigate('/')
          }
        }}
        sendDatalayerEvent={() => { }}
        hasHeaderTitle={false}
      />

      <ArticlesByCategories
        categoryTitle={selectedCategoryKey}
        categoryUrlParam={req.params.categorie}
        articlesByCategory={articlesByCategory} />
    </Layout>
  )
}

export default CategoriesPage