import { normalizeString } from "src/utils/normalizeString";
import SubCategory from "./SubCategory";
import { GroupedSubCategories, ListableCategories } from "src/components/CategoriesSidebar";

export class SidebarModel implements ListableCategories {
  public sidebarTitle: string;
  public subCategoriesByCategory: {
    categoryTitle: string;
    normalizedCategoryTitle: string;
    subCategories: SubCategory[];
  }[]
  constructor(sideBarTitle: string, subCategoriesByCategory: GroupedSubCategories) {
    this.sidebarTitle = sideBarTitle;
    this.subCategoriesByCategory = subCategoriesByCategory;

  }
  static get(sidebarTitle:string, subCategories: SubCategory[]): ListableCategories {
    return new SidebarModel(sidebarTitle, SidebarModel.groupSubCategoriesByCategory(subCategories, sidebarTitle));
  }


private static groupSubCategoriesByCategory(subCategories: SubCategory[], selectedCategoryTitle: string): GroupedSubCategories {
  const groupedSubCategories: string[] = [selectedCategoryTitle];
  subCategories.forEach((item) => {
    if (!groupedSubCategories.includes(item.categoryTitle)) {
      groupedSubCategories.push(item.categoryTitle);
    }
  })
  return groupedSubCategories.filter(item => item).map((category) => ({
    categoryTitle: category,
    normalizedCategoryTitle: normalizeString(category),
    subCategories: subCategories.filter((item) => item.categoryTitle === category),
  }))
  }
}
