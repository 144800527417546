import { SubCategories } from "./SubCategories";

export default class SubCategory {
  id: string;
  uuid: string;
  title: string;
  categoryId: string;
  categoryTitle: string;
  intercomId?: string;

  constructor(id: string, uuid: string, title: string, categoryId: string, categoryTitle: string, intercomId?: string) {
    this.id = id;
    this.uuid = uuid;
    this.title = title;
    this.categoryId = categoryId;
    this.categoryTitle = categoryTitle;
    this.intercomId = intercomId;
  }
}

export const SubCategoriesParser = (data: any): SubCategories => {
  if (!Object.hasOwn(data, "pt") && !Object.hasOwn(data, "en") && !Object.hasOwn(data, "es")) return {} as SubCategories;
  const { pt, en, es } = data
  return {
    pt: pt.map((item: any) => new SubCategory(item.id, item.uuid, item.title, item.categoryId, item.categoryTitle, item.intercomId)),
    en: en.map((item: any) => new SubCategory(item.id, item.uuid, item.title, item.categoryId, item.categoryTitle, item.intercomId)),
    es: es.map((item: any) => new SubCategory(item.id, item.uuid, item.title, item.categoryId, item.categoryTitle, item.intercomId)),
  } as SubCategories;
}
